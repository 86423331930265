import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start gap-y-4 overflow-auto scrollbar pr-1" }
const _hoisted_2 = { class: "text-center w-full" }
const _hoisted_3 = { class: "text-center text-sm text-grey-darker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_auth_container = _resolveComponent("auth-container")!

  return (_openBlock(), _createBlock(_component_auth_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t("auth.registration_success")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("auth.registration_success_text")), 1)
      ]),
      _createVNode(_component_custom_button, {
        id: 'register-button',
        class: "w-full mt-5",
        buttonStyle: _ctx.buttonStyle,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: _ctx.cancelRoute })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.to_login")), 1)
        ]),
        _: 1
      }, 8, ["buttonStyle"])
    ]),
    _: 1
  }))
}