
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import AuthContainer from "./containers/AuthContainer.vue";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
    name: "Registration",
    components: { AuthContainer, CustomButton },
    setup() {
        const route = useRoute();
        const buttonStyle = computed(() => {
            let style = {};
            if (route.name?.toString().includes("CustomerAuth")) {
                style = {
                    standardBackgroundColor: "black-lightest",
                    standardBackgroundHoverColor: "black-light",
                    standardBackgroundPressedColor: "black-light",
                    standardBorderPressedColor: "black-light",
                    standardBorderFocusColor: "black-light",
                };
            }
            return style;
        });
        const cancelRoute = computed(() => {
            let routeName = "AuthLogin";
            if (route.name?.toString().includes("CustomerAuth")) {
                routeName = "CustomerAuthLogin";
            }
            return routeName;
        });
        return { buttonStyle, cancelRoute };
    },
});
